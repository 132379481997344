body,
html {
  @extend %smoothing;
}

.right {
  @include breakpoint($small) {
    float: right;
    margin: 0 15px 25px 25px;
  }
  @include breakpoint($medium) {
    float: right;
    margin: 0 15px 25px 25px;
  }
  @include breakpoint($large) {
    float: right;
    margin: 0 15px 25px 25px;
  }
}

.main blockquote {
  /* Negate theme styles */
  border: 0;
  margin: 0 0 15px;
  padding: 0;
  background: none;
  p {
    color: #666;
    @extend %font-main;
    font-size: 1.2em;
    font-style: italic;
    line-height: 1.4 !important;
    margin: 0;
    position: relative;
    text-shadow: 0 1px white;
  }
}

.main cite {
  font-size: .8em;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-shadow: 0 1px white;
}
