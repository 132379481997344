.main h1 {
  padding-top: 20px;
}

.main h1, .main h2, .main h3, .main h4 {
  @extend %font-main;
  strong {
    @extend %font-bold;
    //letter-spacing: -.01em;
  }
}

.main h3.areas__sub,
.main h2.areas__sub {
  @extend %font-caps;
}

.main h2.areas__sub {
  font-size: 1.4333em;
}

.main h4,
.main h5,
.main h6 {
  font-size: 1em;
  font-weight: normal;
  strong {
    font-weight: normal;
  }
  &.view {
    font-size: 1.3em;
  }
}

.main ul.areas__container {
  margin: 0;
  li.areas {
    margin: 0 15px 20px 0;
  }
}
