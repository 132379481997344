header .container {
  position: relative;
  padding-top: 5px;
  padding-bottom: 5px;
}

.top-container-wrapper {
    background: #fff;
  @include breakpoint($medium) {
    background: #fff;
  }
  @include breakpoint($large) {
    background: #fff;
  }
}

