.main a.btn,
.btn {
  @extend %font-bold;
  text-transform: uppercase;
  background: #1186a4;
  color: #fff;
  @include breakpoint($large) {
    padding: 15px;
  }
}
