.view-iyws-callouts .view-content,
.view-overview-page-blocks .view-content {
  @include breakpoint($all) {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    /* //display: flex;
    //flex-direction: row;
    //justify-content: space-between;*/
    color: #fff;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
}

.view-overview-page-blocks .view-content a:hover img {
  opacity: .5;
}

.feature-block {
  //text-align: center;
  position: relative;
  width: 100%;
  margin: 0 15px 20px 0;
  //background: #fff;
  @include breakpoint($all) {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    //display: flex;
    //flex-flow: column;
    //width: auto;
    -webkit-flex: 1 0 280px;
    -ms-flex: 1 0 280px;
    flex: 1 0 280px;
    -webkit-flex-flow: column;
    -ms-flex-flow: column;
    flex-flow: column;
  }
  h2 {
    font-size: 1.3em;
    min-height: 84px;
    padding: 20px 20px 20px 100px;
    text-transform: uppercase;
    color: #fff;
    //background: #0d3646;
    @extend %font-bold;
  }
  &.views-row-1 h2 {
    line-height: 2;
    background: #0d3646 url(../images/water-01.svg) no-repeat left;
  }
  &.views-row-2 h2 {
    background: #6AA06A url(../images/seed-01.svg) no-repeat left;
  }
  &.views-row-last {
    margin-right: 0;
  }
}

.feature-block a {
    background: #fff;
}

.feature-block a {
  //border: 1px solid #f3f3f3;
  img {
    width: 100%;
    flex-shrink: 0;
  }
}

.feature-block:focus, .feature-block:hover {
    border-left-color: #DADDE0;
    -webkit-transform: translateY(-8px) scale(1.01);
    -moz-transform: translateY(-8px) scale(1.01);
    -ms-transform: translateY(-8px) scale(1.01);
    transform: translateY(-8px) scale(1.01);
}

.feature-block:focus, .feature-block:hover {
    box-shadow: 3px 5px 10px 0 rgba(13,23,33,.2);
    -webkit-transform: translateY(-10px) scale(1.01);
    -moz-transform: translateY(-10px) scale(1.01);
    -ms-transform: translateY(-10px) scale(1.01);
    transform: translateY(-10px) scale(1.01);
    background: #fff;
}

.feature-block.views-row-1:hover h2,
.feature-block.views-row-1:focus h2 {
    background: #2a363b url(../images/water-01.svg) no-repeat left;
    color: #d9d581;
}

.feature-block.views-row-2:hover h2,
.feature-block.views-row-2:focus h2 {
    background: #3c7c36 url(../images/seed-01.svg) no-repeat left;
    color: #fef1e1;
}

.feature-block  {
    -moz-transition: all 450ms cubic-bezier(.32,1.25,.375,1.15);
    transition: all 450ms cubic-bezier(.32,1.25,.375,1.15);
}

.feature-block  a {
    cursor: pointer;
    -moz-transition: all 225ms ease;
    transition: all 225ms ease;
}



.views-field-field-overview-top-image img {
  margin-bottom: -3px;
}

.view-overview-page-blocks .views-row span.title {
  font-size: 1.5em;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  color: #fff;
  background: #5497cf;
  @extend %font-bold;
}

.view-overview-page-blocks .views-row h2.title {
  font-size: 1.5em;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-bottom: 0;
  padding: 10px;
  color: #fff;
  background: #5497cf;
  @extend %font-bold;
}

.view-overview-page-blocks .views-row {
  //text-align: center;
  position: relative;
  width: 100%;
  margin: 0 15px 20px 0;
  background: #fff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .4);
  @include breakpoint($all) {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    //display: flex;
    //flex-flow: column;
    //width: auto;
    -webkit-flex: 1 0 180px;
    -ms-flex: 1 0 180px;
    flex: 1 0 180px;
    -webkit-flex-flow: column;
    -ms-flex-flow: column;
    flex-flow: column;
  }
  &.views-row-last {
    margin-right: 0;
  }
}

.areas__link {
  font-size: .8em;
  display: flex;
  min-height: 40px;
  margin-top: auto;
  padding: 10px;
  text-transform: uppercase;
  color: #000;
  background: #dad7d7;
  align-items: center;
  @extend %font-bold;
  span {
    margin: 0 auto;
  }
  a {
    display: block;
    text-align: left;
    color: #000;
    &:after {
      position: absolute;
      padding: 0 3px 0 5px;
      content: '\f0da';
      color: #fff;
      border-radius: 50%;
      @extend %font-icon;
    }
  }
}

body.front .wrapper {
  @include breakpoint($all) {
    display: inline-block;
    width: 100%;
    background: url(../images/ep_naturalwhite.jpg) repeat;
  }
}

.date {
  position: absolute;
  top: 0;
  left: 0;
  width: 90px;
  text-align: center;
  background: #cab999;
}

.date-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 120px;
  max-width: 120px;
  height: 100%;
  padding: 0;
  color: #000;
  border: none;
  background: #cbba97;
}

.date-content {
  position: relative;
  min-height: 120px;
  padding: 20px 14px 13px 24px;
}

.front .date-content {
  padding: 20px 14px 13px 24px;
}

.date {
  line-height: 1;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.front .date {
  position: absolute;
  bottom: unset;
  left: unset;
  padding: 20px 0;
  top: 50%;
  text-align: center;
  background: inherit;
  line-height: 1;
  width: 100%;
}

.m {
  font-size: .8em;
  display: block;
  padding: 5px 0;
  letter-spacing: .08em;
  text-transform: uppercase;
  @extend %font-thin;
}

.y {
  font-size: .8em;
  margin-top: 3px;
  padding-top: 3px;
  border-top: 1px solid #000;
}

.d {
  font-size: 3em;
  display: block;
  margin-bottom: 6px;
  @extend %font-bold;
}

#block-views-events-block,
#block-views-news-block {
  @include breakpoint($all) {
    float: right;
    width: 45%;
  }
}

#block-views-news-block {
  clear: right;
}

.main .feature-block__eventslist a:hover {
  text-decoration: none;
}



.feature-block__eventslist li:focus, .feature-block__eventslist li:hover {
    box-shadow: 3px 5px 10px 0 rgba(13,23,33,.2);
    -webkit-transform: translateY(-10px) scale(1.01);
    -moz-transform: translateY(-10px) scale(1.01);
    -ms-transform: translateY(-10px) scale(1.01);
    transform: translateY(-10px) scale(1.01);
    //background: #6CB4DE;
}

.feature-block__eventslist li:hover .date-box,
.feature-block__eventslist li:hover .date,
.feature-block__eventslist li:focus .date-box,
.feature-block__eventslist li:focus .date   {
  background: #ccc;
}

.feature-block__eventslist li:hover .date-content,
.feature-block__eventslist li:focus .date-content  {
  background: #0F7E99;
}
.feature-block__eventslist li:hover h3,
.feature-block__eventslist li:hover .event-location,
.feature-block__eventslist li:hover .event-time,
.feature-block__eventslist li:focus h3,
.feature-block__eventslist li:focus .event-location,
.feature-block__eventslist li:focus .event-time  {
  color: #fff;
}

.feature-block__eventslist li {
    -moz-transition: all 450ms cubic-bezier(.32,1.25,.375,1.15);
    transition: all 450ms cubic-bezier(.32,1.25,.375,1.15);
}

.feature-block__eventslist li a {
    cursor: pointer;
    -moz-transition: all 225ms ease;
    transition: all 225ms ease;
}



.feature-block__eventslist {
  padding: 30px 0;
  //margin: 20px;
  background: #fff;
  @include breakpoint($all) {
    //width: 45%;
    //float: left;
    padding: 0;
    background: none;
  }
  li {
    position: relative;
    display: block;
    overflow: hidden;
    width: 100%;
    margin-bottom: 30px;
    padding-left: 120px;
    //  margin-bottom: 50px;
    //  padding-left:110px;
    // display: inline-block;
    // background: #fff;
    // width: 100%;
    background: #fff;
    img {
      float: left;
      width: 30%;
      margin-right: 20px;
    }
  }
  h3 {
    line-height: 1.3;
    margin-bottom: 3px;
    color: #000;
  }
  .event-location {
    font-style: italic;
    margin-bottom: 5px;
    color: #685a46;
  }
  .event-time {
    color: #666;
  }
}

.feature-block__eventsheading {
  font-size: 1.7em;
  margin-bottom: 15px;
  letter-spacing: .03em;
  text-transform: uppercase;
  @extend %font-bold;
  a {
    color: black;
  }
}

.node-type-event {
  .field-name-field-event-date {
    font-style: italic;
    color: #333;
  }
  .field-name-field-event-location {
    color: #685a46;
  }
}

//  Open Region Area on Home
#block-views-iyws-callouts-block {
  @include breakpoint($all) {
    float: left;
    width: 52%;
  }
}

.feature-block {
  margin-bottom: 30px;
  border: none;
  background: none;
}

.feature-block.views-row-4,
.feature-block.views-row-last {
  margin-right: 0;
}

.feature-block--content {
  display: inline-block;
  max-width: 100%;
  //margin: -20px 10px 10px 10px;
  padding: 20px;
  // background: #fff;
  .front & {
    @include breakpoint($medium) {
      min-height: 300px;
    }
    @include breakpoint($large) {
      min-height: 300px;
    }
  }
  h2 {
    font-size: 1.2em;
    letter-spacing: .03em;
    color: #000;
    @extend %font-bold;
  }
  h3 {
    font-size: 1.2em;
    color: #7c6221;
  }
  p {
    line-height: 1.4;
    margin: 0 0 10px;
    color: #000;
    strong {
      @extend %font-bold;
    }
    a {
      word-wrap: break-word;
      /* This is the dangerous one in WebKit, as it breaks things wherever */
      word-break: break-all;
      /* Instead use this non-standard one: */
      word-break: break-word;
      -ms-word-break: break-all;
      -webkit-hyphens: auto;
      -moz-hyphens: auto;
      hyphens: auto;
      color: #333;
      overflow-wrap: break-word;
      /* Adds a hyphen where the word breaks, if supported (No Blink) */
      -ms-hyphens: auto;
    }
  }
}

@media all and (-ms-high-contrast:none) {
  .view-overview-page-blocks .views-row {
    display: block;
  }
  /* IE10 */
  *::-ms-backdrop,
  .view-overview-page-blocks .views-row {
    display: block;
  }
  /* IE11 */
}

@media all and (-ms-high-contrast:none) {
  .front .feature-block {
    display: block;
    -webkit-flex: 1 0 280px;
    -ms-flex: 1 0 280px;
    flex: 1 0 280px;
  }
  /* IE10 */
  *::-ms-backdrop,
  .front .feature-block {
    display: block;
    -webkit-flex: 1 0 280px;
    -ms-flex: 1 0 280px;
    flex: 1 0 280px;
  }
  /* IE11 */
}


.circle {
  display: block;
  margin: 0 auto;
  width: 90px;
  height: 90px;
  border: 2px solid #7ba877;
  border-radius: 50%;
  @include breakpoint($all) {
    float: left;
    margin: 0 20px 20px;
  }
  img {
    margin: -5px 0 0 -1px;
    -webkit-shape-outside: circle();
    shape-outside: circle();
  }
}



.rectangle-wrapper {
  min-height: 50px;
  background: #fff;
  .container {
    position: relative;
    z-index: 100;
    padding: 20px;
    background: #fff;
    @include breakpoint($medium) {
      margin-top: -80px;
    }
    @include breakpoint($large) {
      margin-top: -80px;
    }
  }
  p {
    font-size: 1.1em;
    font-style: italic;
    line-height: 1.3;
    margin: 10px 0 0;
    color: #423f3f;
    @include breakpoint($medium) {
      width: 80%;
    }
    @include breakpoint($large) {
      width: 80%;
    }
  }
  .btn {
    width: 100%;
    margin: 20px 0 0;
    padding: 20px 40px;
    @include breakpoint($all) {
      width: auto;
      margin-top: 10px;
    }
    @include breakpoint($large) {
      float: right;
      margin-top: -65px;
    }
    @include breakpoint($medium) {
      float: right;
      margin-top: -65px;
    }
  }
  h2 {
    font-size: 2em;
    margin-bottom: 10px;
    color: #5497cf;
    text-shadow: 1px 1px 0 rgb(255, 255, 255);
    @extend %font-bold;
  }
  p {
    @extend %font-thin;
  }
}



.rectangle-wrapper .btn:focus, .rectangle-wrapper .btn:hover {
    box-shadow: 3px 5px 10px 0 rgba(13,23,33,.2);
    -webkit-transform: translateY(-10px) scale(1.01);
    -moz-transform: translateY(-10px) scale(1.01);
    -ms-transform: translateY(-10px) scale(1.01);
    transform: translateY(-10px) scale(1.01);
    background: #0d3646;
    color: #41d4f8;
}


.rectangle-wrapper .btn {
    -moz-transition: all 450ms cubic-bezier(.32,1.25,.375,1.15);
    transition: all 450ms cubic-bezier(.32,1.25,.375,1.15);
}

.rectangle-wrapper a.btn {
    cursor: pointer;
    -moz-transition: all 225ms ease;
    transition: all 225ms ease;
}
