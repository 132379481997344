.region-fullscreen-slideshow {
  position: relative;
}

.flex-pauseplay a:before {
  font-size: 14px;
}

.flex-pauseplay a {
  width: 15px;
  height: 15px;
  bottom: 10px;
  color: #fff;
}

.flex-direction-nav a {
  color: #fff;
}

.flexslider {
  z-index: 0;
}
