@import url("https://fonts.googleapis.com/css?family=Oswald:300,400");
.main h3.areas__sub,
.main h2.areas__sub {
  font-family: 'Oswald', sans-serif;
  font-weight: 300; }

.menu.sf-horizontal li ul li a, .main blockquote p, .main h1, .main h2, .main h3, .main h4, .page-title h1 {
  font-family: 'Oswald', sans-serif;
  font-weight: 300; }

.main h1 strong, .main h2 strong, .main h3 strong, .main h4 strong, .main a.btn,
.btn, .project-institutions:before,
.project-cooperators:before,
.project-funding:before,
.project-investigators:before, .field-type-taxonomy-term-reference a, .feature-block h2, .view-overview-page-blocks .views-row span.title, .view-overview-page-blocks .views-row h2.title, .areas__link, .d, .feature-block__eventsheading, .feature-block--content h2, .feature-block--content p strong, .rectangle-wrapper h2, .slider__heading {
  font-family: 'ProximaNovaRgBold', Arial, Helvetica, sans-serif; }

.m, .rectangle-wrapper p, .slider__content {
  font-family: 'ProximaNovaRegular', Arial, Helvetica, sans-serif; }

.areas__link a:after {
  font-family: 'FontAwesome'; }

body,
html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

body,
html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

header nav {
  position: relative; }
  .lt-ie9 header nav {
    background: #10819E; }

#main-nav .container, #main-nav .region-open {
  padding-top: 2px;
  padding-bottom: 0;
  border-top: none;
  box-shadow: none; }

.menu.sf-horizontal li > a {
  font-family: 'ProximaNovaRgBold';
  font-size: 1.1em;
  color: #fff;
  text-shadow: 0 1px 1px #000; }

.menu.sf-horizontal li ul {
  background: #10819E; }
  .menu.sf-horizontal li ul li a {
    font-size: 1.1em;
    font-weight: 400;
    color: #fff;
    border-left: none;
    text-shadow: none; }
  .menu.sf-horizontal li ul li.last a {
    padding-bottom: 15px; }

.sf-accordion-toggle a {
  color: #000; }

.sf-accordion li a {
  color: #000; }

.sf-accordion ul {
  background: #fff; }

.sf-accordion > li.menuparent ul > li > a {
  color: #000; }

.menu.sf-horizontal li a:hover,
.menu.sf-horizontal li a.active {
  color: #DAECDA;
  text-shadow: 0 1px 1px #000; }

.menu.sf-horizontal li li a:hover,
.menu.sf-horizontal li li a.active {
  color: #fff; }

.menu.sf-horizontal ul li a.active {
  color: #fff; }

.social-nav {
  padding-top: 10px;
  text-align: center; }
  .social-nav span {
    left: 0; }
  .social-nav li {
    display: inline;
    padding-left: 5px; }
  .social-nav a {
    font-size: 1.7em;
    color: #fff; }

.lt-ie9 .right {
  float: right;
  margin: 0 15px 25px 25px; }

.main blockquote {
  /* Negate theme styles */
  border: 0;
  margin: 0 0 15px;
  padding: 0;
  background: none; }
  .main blockquote p {
    color: #666;
    font-size: 1.2em;
    font-style: italic;
    line-height: 1.4 !important;
    margin: 0;
    position: relative;
    text-shadow: 0 1px white; }

.main cite {
  font-size: .8em;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-shadow: 0 1px white; }

.main h1 {
  padding-top: 20px; }

.main h2.areas__sub {
  font-size: 1.4333em; }

.main h4,
.main h5,
.main h6 {
  font-size: 1em;
  font-weight: normal; }
  .main h4 strong,
  .main h5 strong,
  .main h6 strong {
    font-weight: normal; }
  .main h4.view,
  .main h5.view,
  .main h6.view {
    font-size: 1.3em; }

.main ul.areas__container {
  margin: 0; }
  .main ul.areas__container li.areas {
    margin: 0 15px 20px 0; }

.ajax-progress-throbber {
  display: none; }

.views-throbbing {
  display: none; }

.field-label {
  line-height: 1.1; }

#main a[href$='.pdf'] {
  margin-right: 5px;
  padding-right: 20px;
  background: url(/modules/file/icons/application-pdf.png) no-repeat right; }

.element-invisible, caption {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px; }

.chosen-container-multi .chosen-choices {
  border: none;
  background-image: none;
  box-shadow: none; }

.views-exposed-form label {
  margin-bottom: .5em; }

select {
  -moz-appearance: menulist;
  -webkit-appearance: menulist; }

[type=reset],
[type=submit],
button,
html [type=button] {
  -webkit-appearance: button; }

.main input[type=submit].form-submit {
  color: #fff;
  background-color: #1186a4;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.25;
  border-radius: .25rem;
  transition: all .15s ease-in-out;
  -webkit-box-shadow: none;
  box-shadow: none;
  text-shadow: none;
  background-image: none; }

.views-exposed-form .views-exposed-widget .form-submit {
  margin-top: 1.53em; }

.chosen-container,
.chosen-search-input {
  width: 200px !important; }

.chosen-container {
  clear: both; }

select.form-select:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px); }

.form-select {
  display: block;
  width: 100%;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.25;
  color: #495057;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid #666;
  border-radius: .25rem;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s; }

.field .field-label {
  line-height: 1.2; }

.main .collapsible button {
  text-transform: none;
  letter-spacing: 0;
  text-shadow: none;
  font-weight: 300 !important; }

.main .collapsible button:focus,
.main .collapsible button:hover {
  outline: none;
  color: #1186a4; }

.collapsible .views-row {
  border: none; }

.main ul.views p {
  margin-bottom: 15px; }

.not-front .main .views ol li {
  list-style: decimal;
  display: list-item; }

.not-front .main .views ul li ul li {
  list-style: disc;
  display: list-item; }

input#edit-submit-research-projects,
input#edit-submit-seed-grant-program {
  display: none; }

.views-exposed-form {
  margin-bottom: 30px; }

.main a.img[href$='.pdf'] {
  background: none; }

header .container, header .region-open {
  position: relative;
  padding-top: 5px;
  padding-bottom: 5px; }

.top-container-wrapper {
  background: #fff; }
  .lt-ie9 .top-container-wrapper {
    background: #fff; }

.logo {
  width: 100%;
  padding-top: 20px; }
  .logo a {
    font: 0/0 a;
    display: block;
    display: block;
    width: 100%;
    height: 55px;
    margin: 0 auto;
    color: transparent;
    border: 0;
    background: url("../images/logo-01.svg") no-repeat center;
    background-size: 80%;
    text-shadow: none; }
    .lt-ie9 .logo a {
      margin: 0; }
  .lt-ie9 .logo {
    width: 350px;
    background: url("../images/logo.png") no-repeat 5px 13px; }

.site-slogan {
  line-height: 1.3;
  display: block;
  padding-top: 5px;
  text-align: center;
  color: #666; }

.main-container {
  padding-top: 5px; }

.region-open {
  margin: 0 auto;
  padding-top: 30px; }

.region-open {
  max-width: 100%; }
  .lt-ie9 .region-open {
    max-width: 73em; }

#block-views-13bffa061efe8baad84b13911165d55e .view-content {
  max-width: 100%;
  margin: 0 auto;
  padding: 2%; }
  .lt-ie9 #block-views-13bffa061efe8baad84b13911165d55e .view-content {
    max-width: 73em;
    padding: 2% 0; }

.main h1 {
  padding-top: 15px;
  color: #5497cf;
  border-top: 1px solid #ccc; }

.view-overview-image-container {
  margin-bottom: 30px; }
  .lt-ie9 .view-overview-image-container {
    float: right;
    margin: 0 0 20px 30px; }

.breadcrumb {
  margin: 20px 0 30px; }

.page-title {
  position: relative;
  margin: 0;
  padding: 0;
  border-top: 5px solid #10819E;
  background: url(../images/feature2.jpg) no-repeat center center;
  background-size: cover; }
  .page-title .container, .page-title .region-open {
    position: relative;
    z-index: 1; }
  .page-title h1 {
    font-size: 2.333em;
    line-height: 1;
    padding-top: 30px;
    padding-bottom: 30px;
    color: #fff; }

.page-title:before {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  content: '';
  background: rgba(22, 130, 156, 0.78); }

.bottom-wrapper {
  display: none; }

.main a.btn,
.btn {
  text-transform: uppercase;
  background: #1186a4;
  color: #fff; }

.project-period {
  border: 1px solid #333;
  padding: 5px 10px;
  display: inline-block;
  margin-bottom: 5px;
  border-radius: 2px;
  font-size: .9em;
  color: #333;
  background: #fff; }

h3 .project-period {
  border: none; }

.project-institutions:before {
  content: "Project Institutions:"; }

.project-cooperators:before {
  content: "Project Cooperators:"; }

.project-funding:before {
  content: "Project Funding:"; }

.project-investigators:before {
  content: "Project Investigators:"; }

.project-investigators:nth-child(2),
.project-investigators:nth-child(3) {
  display: inline; }

.field-name-field-project-cooperator-s- .field-items .field-item,
.field-name-field-project-investigator-s- .field-items .field-item,
.field-name-field-project-collaborating-inst .field-items .field-item {
  display: inline; }
  .field-name-field-project-cooperator-s- .field-items .field-item:after,
  .field-name-field-project-investigator-s- .field-items .field-item:after,
  .field-name-field-project-collaborating-inst .field-items .field-item:after {
    content: ";\00A0"; }
  .field-name-field-project-cooperator-s- .field-items .field-item:last-child:after,
  .field-name-field-project-investigator-s- .field-items .field-item:last-child:after,
  .field-name-field-project-collaborating-inst .field-items .field-item:last-child:after {
    content: ""; }

.field-items {
  line-height: 1.4; }

.field-name-field-project-abstract .field-items .field-item,
.field-name-field-seed-grant-program-abstrac .field-items .field-item,
.field-name-field-seed-grant-program-publica .field-items .field-item,
.field-name-field-project-results .field-items .field-item,
.field-name-field-publication-abstract .field-items .field-item,
.field-name-field-publication-citation .field-items .field-item,
.field-name-field-publication-journal-title .field-items .field-item,
.field-name-field-publication-cheec-project .field-items .field-item {
  clear: both;
  padding-top: 5px;
  margin-bottom: 25px; }

.field-name-field-publication-author-s- .field-items .field-item:first-child {
  clear: both;
  padding-top: 5px; }

.field-name-field-publication-author-s- .field-items .field-item:last-child {
  clear: both;
  margin-bottom: 25px; }

.field .field-label {
  font-weight: normal; }

.field-type-taxonomy-term-reference .field-item {
  display: inline;
  margin-right: 10px; }

.field-type-taxonomy-term-reference a {
  color: #fff;
  padding: 10px 15px;
  display: inline-block;
  margin-bottom: 10px;
  font-size: .7em;
  border-radius: 2px;
  text-transform: uppercase;
  background: #7ba877; }

.field-type-taxonomy-term-reference .field-item:nth-child(5n+1) a {
  background: #7ba877; }

.field-type-taxonomy-term-reference .field-item:nth-child(5n+2) a {
  background: #1186a4; }

.field-type-taxonomy-term-reference .field-item:nth-child(5n+3) a {
  background: #0d3646; }

.field-type-taxonomy-term-reference .field-item:nth-child(5n+4) a {
  background: #b1a283; }

.field-type-taxonomy-term-reference .field-item:nth-child(5n+5) a {
  background: #0B5669; }

.field-name-field-project-research-type.field-type-taxonomy-term-reference .field-item:nth-child(5n+1) a {
  border: 1px solid #7ba877;
  color: #4E794E;
  background: #fff; }

.field-name-field-project-research-type.field-type-taxonomy-term-reference .field-item:nth-child(5n+2) a {
  border: 1px solid #1186a4;
  color: #1186a4;
  background: #fff; }

.field-name-field-project-research-type.field-type-taxonomy-term-reference .field-item:nth-child(5n+3) a {
  border: 1px solid #0d3646;
  color: #0d3646;
  background: #fff; }

.field-name-field-project-research-type.field-type-taxonomy-term-reference .field-item:nth-child(5n+4) a {
  border: 1px solid #b1a283;
  color: #847252;
  background: #fff; }

.field-name-field-project-research-type.field-type-taxonomy-term-reference .field-item:nth-child(5n+5) a {
  border: 1px solid #0B5669;
  color: #0B5669;
  background: #fff; }

.not-front .main .view-research-projects ul.views li {
  border-bottom: 20px solid #fff; }
  .not-front .main .view-research-projects ul.views li li {
    border-bottom: none; }
    .not-front .main .view-research-projects ul.views li li li {
      border-bottom: none; }

.main .view-research-projects h3 {
  margin: 20px 0;
  font-family: 'ProximaNovaRgBold', Arial, Helvetica, sans-serif; }

.main .view-research-projects .collapsible h3 {
  font-family: Oswald,sans-serif;
  line-height: 1.4; }

.view-top-image-container {
  text-align: center; }
  .view-top-image-container img {
    width: auto;
    margin-bottom: 10px; }

.view-overview-page-blocks .view-content a:hover img {
  opacity: .5; }

.feature-block {
  position: relative;
  width: 100%;
  margin: 0 15px 20px 0; }
  .feature-block h2 {
    font-size: 1.3em;
    min-height: 84px;
    padding: 20px 20px 20px 100px;
    text-transform: uppercase;
    color: #fff; }
  .feature-block.views-row-1 h2 {
    line-height: 2;
    background: #0d3646 url(../images/water-01.svg) no-repeat left; }
  .feature-block.views-row-2 h2 {
    background: #6AA06A url(../images/seed-01.svg) no-repeat left; }
  .feature-block.views-row-last {
    margin-right: 0; }

.feature-block a {
  background: #fff; }

.feature-block a img {
  width: 100%;
  flex-shrink: 0; }

.feature-block:focus, .feature-block:hover {
  border-left-color: #DADDE0;
  -webkit-transform: translateY(-8px) scale(1.01);
  -moz-transform: translateY(-8px) scale(1.01);
  -ms-transform: translateY(-8px) scale(1.01);
  transform: translateY(-8px) scale(1.01); }

.feature-block:focus, .feature-block:hover {
  box-shadow: 3px 5px 10px 0 rgba(13, 23, 33, 0.2);
  -webkit-transform: translateY(-10px) scale(1.01);
  -moz-transform: translateY(-10px) scale(1.01);
  -ms-transform: translateY(-10px) scale(1.01);
  transform: translateY(-10px) scale(1.01);
  background: #fff; }

.feature-block.views-row-1:hover h2,
.feature-block.views-row-1:focus h2 {
  background: #2a363b url(../images/water-01.svg) no-repeat left;
  color: #d9d581; }

.feature-block.views-row-2:hover h2,
.feature-block.views-row-2:focus h2 {
  background: #3c7c36 url(../images/seed-01.svg) no-repeat left;
  color: #fef1e1; }

.feature-block {
  -moz-transition: all 450ms cubic-bezier(0.32, 1.25, 0.375, 1.15);
  transition: all 450ms cubic-bezier(0.32, 1.25, 0.375, 1.15); }

.feature-block a {
  cursor: pointer;
  -moz-transition: all 225ms ease;
  transition: all 225ms ease; }

.views-field-field-overview-top-image img {
  margin-bottom: -3px; }

.view-overview-page-blocks .views-row span.title {
  font-size: 1.5em;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  color: #fff;
  background: #5497cf; }

.view-overview-page-blocks .views-row h2.title {
  font-size: 1.5em;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-bottom: 0;
  padding: 10px;
  color: #fff;
  background: #5497cf; }

.view-overview-page-blocks .views-row {
  position: relative;
  width: 100%;
  margin: 0 15px 20px 0;
  background: #fff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.4); }
  .view-overview-page-blocks .views-row.views-row-last {
    margin-right: 0; }

.areas__link {
  font-size: .8em;
  display: flex;
  min-height: 40px;
  margin-top: auto;
  padding: 10px;
  text-transform: uppercase;
  color: #000;
  background: #dad7d7;
  align-items: center; }
  .areas__link span {
    margin: 0 auto; }
  .areas__link a {
    display: block;
    text-align: left;
    color: #000; }
    .areas__link a:after {
      position: absolute;
      padding: 0 3px 0 5px;
      content: '\f0da';
      color: #fff;
      border-radius: 50%; }

.date {
  position: absolute;
  top: 0;
  left: 0;
  width: 90px;
  text-align: center;
  background: #cab999; }

.date-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 120px;
  max-width: 120px;
  height: 100%;
  padding: 0;
  color: #000;
  border: none;
  background: #cbba97; }

.date-content {
  position: relative;
  min-height: 120px;
  padding: 20px 14px 13px 24px; }

.front .date-content {
  padding: 20px 14px 13px 24px; }

.date {
  line-height: 1;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%); }

.front .date {
  position: absolute;
  bottom: unset;
  left: unset;
  padding: 20px 0;
  top: 50%;
  text-align: center;
  background: inherit;
  line-height: 1;
  width: 100%; }

.m {
  font-size: .8em;
  display: block;
  padding: 5px 0;
  letter-spacing: .08em;
  text-transform: uppercase; }

.y {
  font-size: .8em;
  margin-top: 3px;
  padding-top: 3px;
  border-top: 1px solid #000; }

.d {
  font-size: 3em;
  display: block;
  margin-bottom: 6px; }

#block-views-news-block {
  clear: right; }

.main .feature-block__eventslist a:hover {
  text-decoration: none; }

.feature-block__eventslist li:focus, .feature-block__eventslist li:hover {
  box-shadow: 3px 5px 10px 0 rgba(13, 23, 33, 0.2);
  -webkit-transform: translateY(-10px) scale(1.01);
  -moz-transform: translateY(-10px) scale(1.01);
  -ms-transform: translateY(-10px) scale(1.01);
  transform: translateY(-10px) scale(1.01); }

.feature-block__eventslist li:hover .date-box,
.feature-block__eventslist li:hover .date,
.feature-block__eventslist li:focus .date-box,
.feature-block__eventslist li:focus .date {
  background: #ccc; }

.feature-block__eventslist li:hover .date-content,
.feature-block__eventslist li:focus .date-content {
  background: #0F7E99; }

.feature-block__eventslist li:hover h3,
.feature-block__eventslist li:hover .event-location,
.feature-block__eventslist li:hover .event-time,
.feature-block__eventslist li:focus h3,
.feature-block__eventslist li:focus .event-location,
.feature-block__eventslist li:focus .event-time {
  color: #fff; }

.feature-block__eventslist li {
  -moz-transition: all 450ms cubic-bezier(0.32, 1.25, 0.375, 1.15);
  transition: all 450ms cubic-bezier(0.32, 1.25, 0.375, 1.15); }

.feature-block__eventslist li a {
  cursor: pointer;
  -moz-transition: all 225ms ease;
  transition: all 225ms ease; }

.feature-block__eventslist {
  padding: 30px 0;
  background: #fff; }
  .feature-block__eventslist li {
    position: relative;
    display: block;
    overflow: hidden;
    width: 100%;
    margin-bottom: 30px;
    padding-left: 120px;
    background: #fff; }
    .feature-block__eventslist li img {
      float: left;
      width: 30%;
      margin-right: 20px; }
  .feature-block__eventslist h3 {
    line-height: 1.3;
    margin-bottom: 3px;
    color: #000; }
  .feature-block__eventslist .event-location {
    font-style: italic;
    margin-bottom: 5px;
    color: #685a46; }
  .feature-block__eventslist .event-time {
    color: #666; }

.feature-block__eventsheading {
  font-size: 1.7em;
  margin-bottom: 15px;
  letter-spacing: .03em;
  text-transform: uppercase; }
  .feature-block__eventsheading a {
    color: black; }

.node-type-event .field-name-field-event-date {
  font-style: italic;
  color: #333; }

.node-type-event .field-name-field-event-location {
  color: #685a46; }

.feature-block {
  margin-bottom: 30px;
  border: none;
  background: none; }

.feature-block.views-row-4,
.feature-block.views-row-last {
  margin-right: 0; }

.feature-block--content {
  display: inline-block;
  max-width: 100%;
  padding: 20px; }
  .lt-ie9 .front .feature-block--content {
    min-height: 300px; }
  .feature-block--content h2 {
    font-size: 1.2em;
    letter-spacing: .03em;
    color: #000; }
  .feature-block--content h3 {
    font-size: 1.2em;
    color: #7c6221; }
  .feature-block--content p {
    line-height: 1.4;
    margin: 0 0 10px;
    color: #000; }
    .feature-block--content p a {
      word-wrap: break-word;
      /* This is the dangerous one in WebKit, as it breaks things wherever */
      word-break: break-all;
      /* Instead use this non-standard one: */
      word-break: break-word;
      -ms-word-break: break-all;
      -webkit-hyphens: auto;
      -moz-hyphens: auto;
      hyphens: auto;
      color: #333;
      overflow-wrap: break-word;
      /* Adds a hyphen where the word breaks, if supported (No Blink) */
      -ms-hyphens: auto; }

@media all and (-ms-high-contrast: none) {
  .view-overview-page-blocks .views-row {
    display: block; }
  /* IE10 */
  *::-ms-backdrop,
  .view-overview-page-blocks .views-row {
    display: block; }
  /* IE11 */ }

@media all and (-ms-high-contrast: none) {
  .front .feature-block {
    display: block;
    -webkit-flex: 1 0 280px;
    -ms-flex: 1 0 280px;
    flex: 1 0 280px; }
  /* IE10 */
  *::-ms-backdrop,
  .front .feature-block {
    display: block;
    -webkit-flex: 1 0 280px;
    -ms-flex: 1 0 280px;
    flex: 1 0 280px; }
  /* IE11 */ }

.circle {
  display: block;
  margin: 0 auto;
  width: 90px;
  height: 90px;
  border: 2px solid #7ba877;
  border-radius: 50%; }
  .circle img {
    margin: -5px 0 0 -1px;
    -webkit-shape-outside: circle();
    shape-outside: circle(); }

.rectangle-wrapper {
  min-height: 50px;
  background: #fff; }
  .rectangle-wrapper .container, .rectangle-wrapper .region-open {
    position: relative;
    z-index: 100;
    padding: 20px;
    background: #fff; }
    .lt-ie9 .rectangle-wrapper .container, .lt-ie9 .rectangle-wrapper .region-open {
      margin-top: -80px; }
  .rectangle-wrapper p {
    font-size: 1.1em;
    font-style: italic;
    line-height: 1.3;
    margin: 10px 0 0;
    color: #423f3f; }
    .lt-ie9 .rectangle-wrapper p {
      width: 80%; }
  .rectangle-wrapper .btn {
    width: 100%;
    margin: 20px 0 0;
    padding: 20px 40px; }
    .lt-ie9 .rectangle-wrapper .btn {
      float: right;
      margin-top: -65px; }
  .rectangle-wrapper h2 {
    font-size: 2em;
    margin-bottom: 10px;
    color: #5497cf;
    text-shadow: 1px 1px 0 white; }

.rectangle-wrapper .btn:focus, .rectangle-wrapper .btn:hover {
  box-shadow: 3px 5px 10px 0 rgba(13, 23, 33, 0.2);
  -webkit-transform: translateY(-10px) scale(1.01);
  -moz-transform: translateY(-10px) scale(1.01);
  -ms-transform: translateY(-10px) scale(1.01);
  transform: translateY(-10px) scale(1.01);
  background: #0d3646;
  color: #41d4f8; }

.rectangle-wrapper .btn {
  -moz-transition: all 450ms cubic-bezier(0.32, 1.25, 0.375, 1.15);
  transition: all 450ms cubic-bezier(0.32, 1.25, 0.375, 1.15); }

.rectangle-wrapper a.btn {
  cursor: pointer;
  -moz-transition: all 225ms ease;
  transition: all 225ms ease; }

.region-fullscreen-slideshow {
  position: relative; }

.flex-pauseplay a:before {
  font-size: 14px; }

.flex-pauseplay a {
  width: 15px;
  height: 15px;
  bottom: 10px;
  color: #fff; }

.flex-direction-nav a {
  color: #fff; }

.flexslider {
  z-index: 0; }

.field-name-field-profile-additional-roles .field-label,
.field-name-field-education .field-label {
  padding-bottom: 10px; }

.field-name-field-profile-additional-roles .field-items,
.field-name-field-education .field-items {
  clear: left; }
  .field-name-field-profile-additional-roles .field-items .field-item,
  .field-name-field-education .field-items .field-item {
    list-style: disc;
    display: list-item;
    margin-left: 20px; }

.node-type-profile span.field-label {
  padding-bottom: 10px; }

.field-name-field-research-interests {
  clear: left;
  list-style: disc;
  display: list-item;
  margin-left: 20px; }
  .field-name-field-research-interests:first-child {
    padding-top: 10px; }

.not-front .main .views li li {
  display: list-item; }

.not-front .main .views ul li {
  margin: 0;
  list-style: disc; }

.views ul.field-type-text {
  margin: 0 0 20px 20px; }

.slider__text {
  position: relative;
  z-index: 100;
  bottom: 0;
  width: 100%;
  padding: 20px 0 0 0;
  text-align: center;
  background: #fff; }
  .lt-ie9 .slider__text {
    position: absolute;
    border-top: 20px solid #7ba877;
    border-top: 20px solid rgba(123, 168, 119, 0.5);
    border-bottom: 30px solid #7ba877;
    border-bottom: 30px solid rgba(123, 168, 119, 0.5);
    background: #16829c;
    background: rgba(22, 130, 156, 0.78); }

.slider__heading span {
  padding: 0; }

.flexslider {
  background: #fff; }
  .lt-ie9 .flexslider {
    background: #000; }

.slider__bg {
  position: relative;
  z-index: 100;
  bottom: 30px;
  text-align: center; }
  .lt-ie9 .slider__bg {
    position: absolute;
    bottom: 0;
    width: 100%; }

.slider-text--banner {
  padding-bottom: 4%; }

.slider__content {
  font-size: 1em;
  line-height: 1.2;
  color: #000; }
  .lt-ie9 .slider__content {
    color: #fff; }
  .slider__content em {
    font-style: italic; }
  .front .slider__content {
    margin-bottom: 10px; }

.slider__heading {
  font-size: 1.625em;
  line-height: 1;
  z-index: 1;
  padding: 20px;
  color: #1186a4; }
  .lt-ie9 .slider__heading {
    font-size: 2.4em;
    line-height: 1;
    width: 70%;
    margin: 0 auto;
    margin-bottom: 10px;
    padding: 0 0 70px;
    letter-spacing: .03em;
    color: #f1f1f1;
    text-shadow: 0 1px 1px black; }
  .slider__heading span.color1 {
    color: #7faa7b; }
    .lt-ie9 .slider__heading span.color1 {
      color: #D1EBD1; }
  .slider__heading span.color2 {
    color: #c1ba3a; }
    .lt-ie9 .slider__heading span.color2 {
      color: #D9D581; }
  .slider__heading strong {
    color: #702323; }

.view.view-home-slideshow p {
  margin: 0 auto; }

.view-home-slideshow img {
  width: 100%; }
