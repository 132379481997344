.logo {
  width: 100%;
  padding-top: 20px;
  @include breakpoint($all) {
    padding-top: 0;
  }
  a {
    font: 0/0 a;
    display: block;
    display: block;
    width: 100%;
    height: 55px;
    margin: 0 auto;
    color: transparent;
    border: 0;
    background: url('../images/logo-01.svg') no-repeat center;
    background-size: 80%;
    text-shadow: none;
    @include breakpoint($intermedium) {
      background: url('../images/logo-01.svg') no-repeat center;
      background-size: 50%;
    }
    @include breakpoint($all) {
      float: left;
      width: 250px;
      height: 75px;
      margin: 0;
      background: url('../images/logo-01.svg') no-repeat -12px;
      background-size: 100%;
    }
    @include breakpoint($medium) {
      margin: 0;
    }
  }
  .lt-ie9 & {
    width: 350px;
    background: url('../images/logo.png') no-repeat 5px 13px;
  }
}

.site-slogan {
  line-height: 1.3;
  display: block;
  padding-top: 5px;
  text-align: center;
  color: #666;
  @include breakpoint($all) {
    float: left;
    width: 250px;
    text-align: left;
  }
}
