header nav {
  position: relative;
  @include breakpoint($medium) {
    background: #10819E
  }
  @include breakpoint($large) {
    background: #10819E ;
  }
}

#main-nav .container {
  padding-top: 2px;
  padding-bottom: 0;
  border-top: none;
  box-shadow: none;
}

.menu.sf-horizontal li > a {
  font-family: 'ProximaNovaRgBold';
  font-size: 1.1em;
  color: #fff;
  text-shadow: 0 1px 1px #000;
}

.menu.sf-horizontal li ul {
  background: #10819E ;
  li a {
    font-size: 1.1em;
    font-weight: 400;
    color: #fff;
    border-left: none;
    text-shadow: none;
    @extend %font-main;
  }
  li.last a {
    padding-bottom: 15px;
  }
}

.sf-accordion-toggle a {
  color: #000;
}

.sf-accordion li a {
  color: #000;
}

.sf-accordion ul {
  background: #fff;
}

.sf-accordion > li.menuparent ul > li > a {
  color: #000;
}

.menu.sf-horizontal li a:hover,
.menu.sf-horizontal li a.active {
  color: #DAECDA;
  text-shadow: 0 1px 1px #000;
}

.menu.sf-horizontal li li a:hover,
.menu.sf-horizontal li li a.active {
  color: #fff;
  //text-shadow: none;
}

.menu.sf-horizontal ul li a.active {
  color: #fff;
}

.menu.sf-horizontal ul {
  //top: 2em !important;
}

.social-nav {
  padding-top: 10px;
  text-align: center;
  @include breakpoint($all) {
    float: right;
    margin-top: 10px;
    text-align: right;
  }
  span {
    left: 0;
  }
  li {
    display: inline;
    padding-left: 5px;
  }
  a {
    font-size: 1.7em;
    color: #fff;
  }
}
