.project-period {
border: 1px solid #333;
padding: 5px 10px;
display: inline-block;
margin-bottom: 5px;
border-radius: 2px;
font-size: .9em;
color: #333;
background: #fff;
}

h3 .project-period {
  border: none;
}

.project-institutions ,
.project-cooperators  ,
.project-funding ,
.project-investigators {
  &:before {
    @extend %font-bold;
  }
}

.project-institutions:before {
    content: "Project Institutions:";
}
.project-cooperators:before {
    content: "Project Cooperators:";
}
.project-funding:before {
    content: "Project Funding:";
}
.project-investigators:before {
    content: "Project Investigators:";
}

.project-investigators:nth-child(2),
.project-investigators:nth-child(3)  {
  display: inline;
}

.field-name-field-project-cooperator-s-,
.field-name-field-project-investigator-s-,
.field-name-field-project-collaborating-inst {
  .field-items .field-item {
    display: inline;
    &:after {
      content: ";\00A0";
    }
    &:last-child:after {
      content: "";
    }
  }
}


.field-items {
  line-height: 1.4;
}

.field-name-field-project-abstract,
.field-name-field-seed-grant-program-abstrac,
.field-name-field-seed-grant-program-publica,
.field-name-field-project-results,
.field-name-field-publication-abstract,
.field-name-field-publication-citation,
.field-name-field-publication-journal-title,
.field-name-field-publication-cheec-project  {
    .field-items .field-item {
      clear: both;
      padding-top: 5px;
      margin-bottom: 25px;
    }
}

.field-name-field-publication-author-s- .field-items .field-item:first-child {
      clear: both;
      padding-top: 5px;
}

.field-name-field-publication-author-s- .field-items .field-item:last-child {
      clear: both;
      margin-bottom: 25px;
}


.field .field-label {
  font-weight: normal;
}

.field-type-taxonomy-term-reference {
  .field-item {
    display: inline;
    margin-right: 10px;
  }
  a {
    color: #fff;
    @extend %font-bold;
    padding: 10px 15px;
    display: inline-block;
    margin-bottom: 10px;
    font-size: .7em;
    border-radius: 2px;
    text-transform: uppercase;
    background: #7ba877;
  }
    .field-item:nth-child(5n+1) a {
      background: #7ba877;
    }
     .field-item:nth-child(5n+2) a {
      background: #1186a4;
    }
     .field-item:nth-child(5n+3) a {
      background: #0d3646;
    }
     .field-item:nth-child(5n+4) a {
      background: #b1a283;
    }
     .field-item:nth-child(5n+5) a {
      background: #0B5669;
    }
}

.field-name-field-project-research-type.field-type-taxonomy-term-reference {
    .field-item:nth-child(5n+1) a {
      border: 1px solid #7ba877;
      color: #4E794E;
      background: #fff;
    }
     .field-item:nth-child(5n+2) a {
      border: 1px solid #1186a4;
      color: #1186a4;
      background: #fff;
    }
     .field-item:nth-child(5n+3) a {
      border: 1px solid #0d3646;
      color: #0d3646;
      background: #fff;
    }
     .field-item:nth-child(5n+4) a {
      border: 1px solid #b1a283;
      color: #847252;
      background: #fff;
    }
     .field-item:nth-child(5n+5) a {
      border: 1px solid #0B5669;
      color: #0B5669;
      background: #fff;
    }
}

.not-front .main .view-research-projects  ul.views li {
    border-bottom: 20px solid #fff;
    li {
      border-bottom: none;
      li {
        border-bottom: none;
      }
    }
}

.main .view-research-projects h3 {
  margin: 20px 0;
  font-family: 'ProximaNovaRgBold', Arial, Helvetica, sans-serif;
}

.main .view-research-projects .collapsible h3 {
  font-family: Oswald,sans-serif;
  line-height: 1.4;
}

// top image
.view-top-image-container {
  text-align: center;
  img {
    width:auto;
    margin-bottom: 10px;
  }
}

