.main-container {
  padding-top: 5px;
}

.region-open {
  margin: 0 auto;
  padding-top: 30px;
  @extend .container;
}

.region-open {
  max-width: 100%;
  @include breakpoint($small) {
    max-width: 100%;
  }
  @include breakpoint($medium) {
    max-width: 73em;
  }
  @include breakpoint($large) {
    max-width: 73em;
  }
}


#block-views-13bffa061efe8baad84b13911165d55e {
  .view-content {
    max-width: 100%;
    margin: 0 auto;
    padding: 2%;
    @include breakpoint($small) {
      max-width: 100%;
      padding: 2% 0;
    }
    @include breakpoint($medium) {
      max-width: 73em;
      padding: 2% 0;
    }
    @include breakpoint($large) {
      max-width: 73em;
      padding: 2% 0;
    }
  }
}

.main h1 {
  padding-top: 15px;
  color: #5497cf;
  border-top: 1px solid #ccc;
}

.view-overview-image-container {
  margin-bottom: 30px;
  @include breakpoint($medium) {
    float: right;
    margin: 0 0 20px 30px;
  }
  @include breakpoint($large) {
    float: right;
    margin: 0 0 20px 30px;
  }
}

.breadcrumb {
  margin: 20px 0 30px;
}

.page-title {
  position: relative;
  margin: 0;
  padding: 0;
  border-top: 5px solid #10819E ;
  background: url(../images/feature2.jpg) no-repeat center center;
  background-size: cover;
  .container {
    position: relative;
    z-index: 1;
  }
  h1 {
    font-size: 2.333em;
    line-height: 1;
    padding-top: 30px;
    padding-bottom: 30px;
    color: #fff;
    @extend %font-main;
  }
}

.page-title:before {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  content: '';
  background: rgba(22, 130, 156, .78);
}

.bottom-wrapper {
  display: none;
}
