.ajax-progress-throbber {
  display: none;
}

.views-throbbing {
  display: none
}

.field-label {
  line-height: 1.1;
}

#main a[href$='.pdf'] {
  margin-right: 5px;
  padding-right: 20px;
  background: url(/modules/file/icons/application-pdf.png) no-repeat right;
}

.element-invisible {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
}

.chosen-container-multi .chosen-choices {
  border: none;
  background-image: none;
  box-shadow: none;
}

.views-exposed-form label {
  margin-bottom: .5em;
}

select {
  -moz-appearance: menulist;
  -webkit-appearance: menulist;
}

[type=reset],
[type=submit],
button,
html [type=button] {
  -webkit-appearance: button;
}

.main input[type=submit].form-submit {
  color: #fff;
  background-color: #1186a4;
  //border-color: #007bff;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.25;
  border-radius: .25rem;
  transition: all .15s ease-in-out;
  -webkit-box-shadow: none;
  box-shadow: none;
  text-shadow: none;
  background-image: none;
}

.views-exposed-form .views-exposed-widget .form-submit {
  margin-top: 1.53em;
}

.chosen-container,
.chosen-search-input {
  width: 200px!important;
}

.chosen-container {
  clear: both;
}

select.form-select:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px);
}

.form-select {
  display: block;
  width: 100%;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.25;
  color: #495057;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid #666;
  border-radius: .25rem;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}
