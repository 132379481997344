.field-name-field-profile-additional-roles,
.field-name-field-education {
  .field-label {
    padding-bottom: 10px;
  }
  .field-items {
  clear: left;
  .field-item {
    list-style: disc;
    display: list-item;
    margin-left: 20px;
  }
}
}

.node-type-profile span.field-label {
  padding-bottom: 10px;
}

.field-name-field-research-interests {
  &:first-child {
    padding-top: 10px;
  }
    clear: left;
    list-style: disc;
    display: list-item;
    margin-left: 20px;
  }

.not-front .main .views li li {display: list-item;}

.not-front .main .views ul {
   li {
  margin: 0;
  list-style: disc;
}
}

.views ul.field-type-text {
  margin: 0 0 20px 20px;
}
