.field .field-label {
  line-height: 1.2;
}

.main .collapsible button {
  text-transform: none;
  letter-spacing: 0;
  text-shadow: none;
  font-weight: 300!important;
}

.main .collapsible button:focus,
.main .collapsible button:hover {
  outline: none;
  color: #1186a4;
}

.collapsible .views-row {
  border: none;
}

.main ul.views p {
  margin-bottom: 15px;
}

.not-front .main .views ol li {
  list-style: decimal;
  display: list-item;
}

.not-front .main .views ul li ul li {
  list-style: disc;
  display: list-item;
}

input#edit-submit-research-projects,
input#edit-submit-seed-grant-program {
  display: none;
}

.views-exposed-form {
  margin-bottom: 30px;
}

.main a.img[href$='.pdf'] {
  background: none;
}
