@import url('https://fonts.googleapis.com/css?family=Oswald:300,400');

%font-caps {
  font-family: 'Oswald', sans-serif;
  font-weight: 300;
}

%font-main {
  font-family: 'Oswald', sans-serif;
  font-weight: 300;
}

%font-bold {
  font-family: 'ProximaNovaRgBold', Arial, Helvetica, sans-serif;
}

%font-thin {
  font-family: 'ProximaNovaRegular', Arial, Helvetica, sans-serif;
}

%font-icon {
  font-family: 'FontAwesome';
}

%smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
