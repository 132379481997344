.slider__text {
  position: relative;
  z-index: 100;
  bottom: 0;
  width: 100%;
  padding: 20px 0 0 0;
  text-align: center;
  background: #fff;
  @include breakpoint($medium) {
    position: absolute;
    border-top: 20px solid #7ba877;
    border-top: 20px solid rgba(123, 168, 119, .5);
    border-bottom: 30px solid #7ba877;
    border-bottom: 30px solid rgba(123, 168, 119, .5);
    background: #16829c;
    background: rgba(22, 130, 156, .78);
  }
  @include breakpoint($large) {
    position: absolute;
    border-top: 20px solid #7ba877;
    border-top: 20px solid rgba(123, 168, 119, .5);
    border-bottom: 30px solid #7ba877;
    border-bottom: 30px solid rgba(123, 168, 119, .5);
    background: #16829c;
    background: rgba(22, 130, 156, .78);
  }
}

.slider__heading span {
    padding: 0;
}

.flexslider {
  background: #fff;
  @include breakpoint($medium) {
    background: #000;
  }
  @include breakpoint($large) {
    background: #000;
  }
}

.slider__bg {
  position: relative;
  z-index: 100;
  bottom: 30px;
  text-align: center;
  @include breakpoint($medium) {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  @include breakpoint($large) {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}

.slider-text--banner {
  padding-bottom: 4%;
}

.slider__content {
  font-size: 1em;
  line-height: 1.2;
  color: #000;
  @extend %font-thin;
  @include breakpoint($medium) {
    color: #fff;
  }
  @include breakpoint($large) {
    color: #fff;
  }
  em {
    font-style: italic;
  }
  .front & {
    margin-bottom: 10px;
  }
}

.slider__heading {
  font-size: 1.625em;
  line-height: 1;
  z-index: 1;
  padding: 20px;
  color: #1186a4;
  @extend %font-bold;
  @include breakpoint($small) {font-size: 2.0625em;}
  @include breakpoint($medium) {
    font-size: 2.4em;
    line-height: 1;
    width: 70%;
    margin: 0 auto;
    margin-bottom: 10px;
    padding: 0 0 70px;
    letter-spacing: .03em;
    color: #f1f1f1;
    text-shadow: 0 1px 1px black;
  }
  @include breakpoint($large) {
    font-size: 2.4em;
    line-height: 1;
    width: 70%;
    margin: 0 auto;
    margin-bottom: 10px;
    padding: 0 0 70px;
    letter-spacing: .03em;
    color: #f1f1f1;
    text-shadow: 0 1px 1px black;
  }
  span.color1 {
    color: #7faa7b;
    @include breakpoint($medium) {
      color: #D1EBD1;
    }
    @include breakpoint($large) {
      color: #D1EBD1;
    }
  }
  span.color2 {
    color: #c1ba3a;
    @include breakpoint($medium) {
      color:#D9D581;
    }
    @include breakpoint($large) {
      color: #D9D581;
    }
  }
  strong {
    color: #702323;
  }
}

.view.view-home-slideshow p {
  margin: 0 auto;
}

.view-home-slideshow img {
  width: 100%;
}
